import React, { useState, useEffect, useRef } from "react";
import { Button, Carousel, Modal, message, Typography, Tooltip } from "antd";
import Slider from "react-slick";
import _ from "lodash";
import { getToken } from "../../utils/authStorage";
import classnames from "classnames";
import IconBookmark from "../../assets/images/ic_bookmark.svg";
import IconFeatureEmpty from "../../assets/images/ic_feature_photo_empty.svg";
import IconImageEmpty from "../../assets/images/ic_image_empty.svg";
import IconAward from "../../assets/images/ic_award.svg";
import PlaceHolderMap from "../../assets/images/ph_map.jpg";
import IconPlay from "../../assets/images/ic_play.svg";
import IconFacebook from "../../assets/images/ic_facebook.svg";
import IconTiktok from "../../assets/images/ic_tiktok.svg";
import IconYoutube from "../../assets/images/ic_youtube.svg";
import IconIns from "../../assets/images/ic_instagram.svg";
import IconScrollLeft from "../../assets/images/ic_scroll_left.svg";
import IconScrollRight from "../../assets/images/ic_scroll_right.svg";
import IconPlayWhite from "../../assets/images/ic_play_white.svg";
import IconMenu from "../../assets/images/ic_menu.svg";
import IconClose from "../../assets/images/ic_close_black.svg";
import IconBack from "../../assets/images/ic_back.svg";
import IconMessage from "../../assets/images/ic_message_black.svg";
import VideoModal from "../../components/component_video_modal";
import moment from "moment";
import { addBookmark } from "../../api/Bookmark";
import { getMedias } from "../../api/Insta";
import {
  CanvasType,
  router_homepage_index,
  router_sign_in,
} from "../../utils/enum";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./canvas_preview.less";
import { Link } from "react-router-dom";
import { TextField } from "@material-ui/core";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { sendMsgEmail } from "../../api/Common";
import IconSuccess from "../../assets/images/ic_check.svg";
import { LazyComponentProps, LazyLoadImage, trackWindowScroll } from "react-lazy-load-image-component";
import { getOptimizedPath, getStaticAssetPath } from "../../utils/utils";
import { useLocation, useHistory } from 'react-router-dom';

const { Paragraph } = Typography;


declare var google: any;
const retailPH = [
  { name: "Brand name" },
  { name: "Brand name" },
  { name: "Brand name" },
  { name: "Brand name" },
];

const softwarePH = [
  { name: "Software" },
  { name: "Software" },
  { name: "Software" },
  { name: "Software" },
];

const employmentPH = [
  { title: "Position 1", isPlaceHolder: true },
  { title: "Position 2", isPlaceHolder: true },
  { title: "Position 3", isPlaceHolder: true },
];

const specialtiesPH = [
  { name: "Specialty" },
  { name: "Specialty" },
  { name: "Specialty" },
  { name: "Specialty" },
];

interface Props extends LazyComponentProps {
  type?: string;
  mode: PreviewMode;
  pageData: any;
  isMobile?: boolean;
  isDetail?: boolean;
  onMessage?: Function;
  curPosition?: PositionTag;
  onGoBack?: Function;
}

export enum PreviewMode {
  CREATE = 0,
  EDIT = 1,
  VIEW = 2,
}

export enum PositionTag {
  NONE = 0,
  MY_WORK = 1,
  ABOUT_ME = 2,
  WHY = 3,
  KPI = 4,
  ADDITIONAL = 5,
  EMPLOYMENT = 6,
  AWARD = 7,
  INS = 8,
  EDUCATION = 9,
  SOCIAL = 10,
  TITLE = 11,
}

const SimpleKVItem = (props: any) => {
  const { hidden, label, value } = props;
  return (
    <div className="kv-item" hidden={hidden}>
      <div className="key">{label || ""}</div>
      <div className="value">{value || ""}</div>
    </div>
  );
};

const RelocateItem = (props: any) => {
  const { hidden, relocateList } = props;
  return (
    <div className="relocate-item" hidden={hidden}>
      <span className="key">Willing to Relocate to</span>
      <div className="location-list">
        {!_.isEmpty(relocateList) ? (
          _.map(relocateList as any[], (item: any, index) => {
            const relocate = `${item.city || ""}, ${item.state || ""}`;
            return (
              <span className="city" key={index}>
                {relocate ? relocate.replace(/^[,]|[,]$/g, "") : ""}
                <span
                  className="point"
                  hidden={index === relocateList.length - 1}
                />
              </span>
            );
          })
        ) : (
          <span className="city">None</span>
        )}
      </div>
    </div>
  );
};

const EducationBackgroundItem = (props: any) => {
  const { hidden, educations } = props;
  const getProgramText = (education: any) => {
    let text = "";
    if (education.program?.name) {
      text += `${education.program.name}`;
    }
    if (education.attendance) {
      text = text + ` - ${education.attendance}% Attendance`;
    }
    if (education.gpa) {
      text = text + ` - ${education.gpa} GPA`;
    }
    return text;
  };

  const getWorkPermitText = (education: any) => {
    let text = "";
    if (education.workPermitHour) {
      text = `Work Permit: ${education.workPermitHour}`;
    }

    if (education.graduationDate) {
      if (text) {
        text += " - ";
      }
      text += `Graduation Date: ${moment(education.graduationDate).utc().format(
        "MM/DD/YYYY"
      )}`;
    }
    return text;
  };

  return (
    <div className="education-item" hidden={hidden}>
      <div className="key">Education Background</div>

      <div className="edu-sec">
        {!_.isEmpty(
          _.filter(educations, (item) => {
            return item.schoolName;
          })
        ) ? (
          _.map(educations, (item, index) => {
            return (
              <div key={index} className="edu-item">
                <span className="school-name">{item.schoolName}</span>
                <span className="info-text">{getProgramText(item)}</span>
                <span className="info-text">{getWorkPermitText(item)}</span>
              </div>
            );
          })
        ) : (
          <span className="school-name">None</span>
        )}
      </div>
    </div>
  );
};

const LicenseItem = (props: any) => {
  const { hidden, categories } = props;
  return (
    <div className="education-item" hidden={hidden}>
      <div className="key">License</div>
      <div className="edu-sec">
        {!_.isEmpty(categories) ? (
          _.map(categories, (item, index) => {
            return (
              <div key={index} className="edu-item">
                <span className="school-name">
                  {item.otherName || item.name}
                </span>
                <span className="info-text">{`${
                  item.licenseNumber ? ` - ${item.licenseNumber}` : ""
                }${item.stateName ? ` - ${item.stateName}` : ""}`}</span>
              </div>
            );
          })
        ) : (
          <span className="school-name">None</span>
        )}
      </div>
    </div>
  );
};

const CanvasPreview: React.FC<Props> = (props) => {
  const {
    type = CanvasType.STUDENT,
    mode,
    pageData,
    isMobile = false,
    isDetail = false,
    onMessage,
    curPosition,
    onGoBack,
    scrollPosition
  } = props;
  const [mediaList, setMMediaList] = useState([] as any[]);
  const [curMedia, setCurMedia] = useState({} as any);
  const [showMenu, setShowMenu] = useState(false);
  const [showMapPH, setShowMapPH] = useState(true);
  const [curCoor, setCurCoor] = useState([0, 0]);
  const [curPlayUrl, setCurPlayUrl] = useState("");
  const [insName, setInsName] = useState("");
  const [insPhotos, setInsPhotos] = useState([] as any[]);
  const [education, setEducation] = useState({} as any);
  const [isBookmark, setBookmark] = useState(false);
  const [visibleMsgModal, setVisibleMsgModal] = useState(false);
  const [visibleSuccessModal, setVisibleSuccessModal] = useState(false);
  const [msgContent, setMsgContent] = useState("");
  const [showMoreEmp, setShowMoreEmp] = useState(false);
  const [aTxtMore, setATxtMore] = useState(false);
  const [txtMore, setTxtMore] = useState([false, false, false]);
  const mediaSlider = useRef(null);
  const [isBtnSticky, setIsBtnSticky] = useState(false);
  const [viewPortHeight, setViewPortHeight] = useState(window.innerHeight);

  const isFirstUpdate = useRef(true);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (isFirstUpdate.current) {
      isFirstUpdate.current = false;
      return;
    }
    const searchParams = new URLSearchParams(location.search);
    if(curPlayUrl !== '') {
      if (searchParams.get('autoplay') !== 'true') {
        searchParams.set('autoplay', 'true');
        window.history.replaceState(null, '', `${location.pathname}?${searchParams.toString()}`);
      }
    } else {
      searchParams.delete('autoplay');
      window.history.replaceState(null, '', `${location.pathname}?${searchParams.toString()}`);
    }
  }, [curPlayUrl])

  const handleScroll = () => {
    setIsBtnSticky(window.scrollY > 120 ? true : false)
  };

  window.addEventListener('scroll', handleScroll);

  useEffect(() => {
    const handleResize = () => {
      setViewPortHeight(window.innerHeight);
    };

    window.visualViewport.addEventListener('resize', handleResize);

    return () => window.visualViewport.removeEventListener('resize', handleResize);
  }, []);

  const bookButtonStyle = {
    top: window.innerWidth < 1025 ? `${viewPortHeight - 70}px` : 'auto',
  }

  useEffect(() => {
    if (curPosition) {
      switch (curPosition) {
        case PositionTag.MY_WORK:
          elementIntoView("my-work", 100);
          break;
        case PositionTag.ABOUT_ME:
          elementIntoView("about-me", 100);
          break;
        case PositionTag.WHY:
          elementIntoView("our-team", 100);
          break;
        case PositionTag.KPI:
          elementIntoView("kpi", 100);
          break;
        case PositionTag.ADDITIONAL:
          elementIntoView("extra-info", 100);
          break;
        case PositionTag.EMPLOYMENT:
          elementIntoView("employ-info", 100);
          break;
        case PositionTag.AWARD:
          elementIntoView("awards", 100);
          break;
        case PositionTag.INS:
          elementIntoView("ins", 100);
          break;
        case PositionTag.EDUCATION:
          elementIntoView("education", 100);
          break;
        case PositionTag.SOCIAL:
          elementIntoView("social-media", 100);
          break;
        case PositionTag.TITLE:
          elementIntoView("title", 100);
          break;
      }
    }
  }, [curPosition]);

  useEffect(() => {
    const location = pageData.location;
    if (
      google &&
      !_.isEmpty(location.coordinates) &&
      location.coordinates.length === 2
    ) {
      if (
        curCoor[0] === location.coordinates[0] &&
        curCoor[1] === location.coordinates[1]
      ) {
        return;
      } else {
        setCurCoor([location.coordinates[0], location.coordinates[1]]);
      }
      const locate = {
        lat: location.coordinates[1],
        lng: location.coordinates[0],
      };
      setShowMapPH(false);
      const map = new google.maps.Map(document.getElementById("map"), {
        center: locate,
        zoom: 15,
      });
      new google.maps.Marker({
        map: map,
        position: locate,
      });
    }
    setBookmark(pageData.bookmarkTag || false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageData]);

  useEffect(() => {
    let medias = [] as any[];
    if (pageData.featuredVideo.video || pageData.featuredVideo.url) {
      medias.push(pageData.featuredVideo);
    }
    const videoList = _.filter(pageData.extraVideo, (video) => {
      return !_.isEmpty(video.video);
    });
    medias = medias.concat(videoList);
    if (!_.isEmpty(pageData.portfolioPhotos)) {
      medias = medias.concat([...pageData.portfolioPhotos]);
    }
    setMMediaList(medias);
    const insMedia = _.find(pageData.socialMediaList, { label: "instagram" });
    if (insMedia && insMedia.value !== insName) {
      setInsName(insMedia.value);
    }

    if (!_.isEmpty(pageData.educations)) {
      const firstItem = pageData.educations[0];
      setEducation(firstItem);
    }
  }, [pageData, insName]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const autoplay = searchParams.get('autoplay');
    if(!curPlayUrl){
      if (autoplay) {
        console.log('Autoplay is enabled.');
        const videoMedia = _.find(mediaList, (item) => {
          return !_.isEmpty(item.video) || !_.isEmpty(item.url);
        });
        if (videoMedia) {
          setCurPlayUrl(videoMedia.video || videoMedia.url);
        }
      } else {
        console.log('Autoplay is disabled')
      }
    }
  }, [location, mediaList]);

  useEffect(() => {
    if (pageData.id && insName) {
      getInsList(pageData.id);
    } else {
      setInsPhotos([]);
    }
  }, [pageData.id, insName]);

  const addOrRemoveBookmark = async () => {
    if (!getToken()) {
      pageData.history.push({
        pathname: router_sign_in,
        search: `?from=${encodeURI(
          window.location.pathname + window.location.search.replace(/&/g, "￥")
        )}`,
      });
      return false;
    }
    const result = await addBookmark(
      "canvas",
      pageData.id,
      isBookmark ? "remove" : "add",
      ""
    );
    if (result && result.success) {
      setBookmark(!isBookmark);
    }
  };

  const getInsList = async (id: number) => {
    const res = await getMedias(id + "", 8);
    if (res && !_.isEmpty(res.medias)) {
      // const top4 = _.take(res.medias, 4);
      setInsPhotos(res.medias);
    }
  };

  const elementIntoView = (id: string, delay: number, block?: any) => {
    setTimeout(() => {
      const element = document.getElementById(id);
      if (element)
        element.scrollIntoView({
          behavior: "smooth",
          block: block || "center",
        });
    }, delay || 1000);
  };

  useEffect(() => {
    const mediaList = _.filter(
      [pageData.featuredVideo, ...pageData.portfolioPhotos],
      (item) => {
        return item.video || item.url;
      }
    );
    if (!_.isEmpty(mediaList)) {
      const stillHas = _.find(mediaList, { image: curMedia.image });
      if (!stillHas) {
        setCurMedia(mediaList[0]);
      }
    }
  }, [pageData, curMedia]);

  const getSocialIcon = (label: string) => {
    if (!label) return "";
    switch (label) {
      case "instagram":
        return IconIns;
      case "youtube":
        return IconYoutube;
      case "facebook":
        return IconFacebook;
      default:
        return IconTiktok;
    }
  };

  const redirectToSocial = (social: any) => {
    let value = (social.value as string) || "";
    if (!value || value === "@") return;
    if (value[0] === "@") {
      value = value.substring(1, value.length);
    }
    switch (social.label) {
      case "instagram":
        window.open(`https://www.instagram.com/${value}`);
        break;
      case "tiktok":
        window.open(`https://www.tiktok.com/@${value}`);
        break;
      case "youtube":
      case "facebook":
        if (value.startsWith("http")) {
          window.open(value);
        } else {
          window.open(`https://${value}`);
        }
        break;
    }
  };

  const getIssueText = (employment: any) => {
    const start = moment(employment.startDate).format("MM/DD/YYYY");
    let end = moment(employment.endDate).format("MM/DD/YYYY");
    end = employment.isCurrentWork ? "Present" : end;
    let time = "";
    if (start === end) {
      time = `(${start})`;
    } else if (start && end) {
      time = `(${start} - ${end})`;
    } else {
      time = "";
    }
    return `${employment.company || ""} ${time}`;
  };

  const getAwardText = (data: any) => {
    if (typeof data === "number") {
      return "Issue Year - Issuer";
    }
    if (data.issueYear && data.issuer) {
      return `${data.issueYear} - ${data.issuer}`;
    } else {
      return data.issueYear ? data.issueYear : data.issuer ? data.issuer : "";
    }
  };

  const RenderInsList = (list: any[], isCarousel = false) => {
    return list.map((item: any, index: number) => {
      const img = item.thumbnail_url || item.media_url;
      const imgItem = (
        <div
          className="ins-item"
          key={"item" + index}
          onClick={() => window.open(`https://www.instagram.com/${insName}`)}
        >
          <div className="img">
            <img src={img} alt="" />
          </div>
        </div>
      );
      return isCarousel ? (
        <div className="item-wrap" key={"wrap" + index}>
          {imgItem}
        </div>
      ) : (
        imgItem
      );
    });
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: isMobile ? 1 : 3,
    arrows: false,
    centerPadding: "60px",
    centerMode: isMobile
  };

  const showProVideoBtn = () => {
    const videoItem = _.find(mediaList, (item) => {
      return item.video || item.url;
    });
    return !videoItem && PreviewMode.VIEW === mode;
  };

  const getSubtitle = () => {
    const subtitle = [] as string[];
    if (pageData.title) {
      subtitle.push(pageData.title);
    }
    if (
      type === CanvasType.STUDENT &&
      education.program &&
      education.program.name
    ) {
      subtitle.push(education.program.name);
    }
    if (type === CanvasType.PROFESSIONAL && !_.isEmpty(pageData.categories)) {
      const categoryText = _.map(pageData.categories, (item) => {
        return item.name === "Other" ? item.otherName : item.name;
      }).join(", ");
      if (categoryText) {
        subtitle.push(categoryText);
      }
    }
    return (
      <>
        <span className="mr-16">{subtitle.join(", ")}</span>
        {pageData.location?.location && (
          <span className="flex">
            <LocationOnIcon />
            {pageData.location.location}
          </span>
        )}
      </>
    );
  };

  const getInsDisplayName = () => {
    if (insName) {
      if (insName.startsWith("@")) {
        return insName;
      } else {
        return `@${insName}`;
      }
    } else {
      return "@account";
    }
  };

  /* Send message */

  const sendMessage = () => {
    if (mode !== PreviewMode.VIEW) {
      return;
    }
    if (!getToken()) {
      const path = window.location.pathname;
      pageData.history && pageData.history.push(`/sign-in?from=${path}`);
    }
    setVisibleMsgModal(true);
  };

  const sendMsg = () => {
    if (!pageData.owner) {
      return;
    }
    if (!msgContent) {
      message.info("please input your message");
      return;
    }
    const params = {
      msg: msgContent,
      template: "msg_to_member",
      users: [pageData.owner],
      extra: {
        canvas_id: pageData.id,
      },
    };
    sendMsgEmail(params).then(() => {
      onMsgClose();
      setVisibleSuccessModal(true);
    });
  };

  const onMsgClose = () => {
    setVisibleMsgModal(false);
    setMsgContent("");
  };

  function bookBtnClicked() {
    const isUrl = (url: string) => {
      const urlPattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([\/\w .-]*[\?&=%]*)*\/?$/;
      return urlPattern.test(url);
    };
    
    const isPhoneNumber = (phoneNumber: string) => {
      const phonePattern = /^\+?[0-9()\-\s]+$/;
      return phonePattern.test(phoneNumber);
    };
    
    const isEmail = (email: string) => {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email);
    };
    
    const addLinkProtocol = (url: string) => {
      if (url && !/^https?:\/\//i.test(url)) {
        return 'http://' + url;
      }
      return url;
    };
    
    const formatLink = (link: string) => {
      console.log('formatLink ',link)
      if (isUrl(link)) {
        return addLinkProtocol(link);
      } else if (isPhoneNumber(link)) {
        return 'tel:' + link.replace(/[^\d+]/g, '');
      } else if (isEmail(link)) {
        return 'mailto:' + link;
      }
      return link;
    };
    
    const bookingLink = formatLink(pageData.bookingUrl);
    console.log('bookingLink ',bookingLink)
    window.open(bookingLink, '_blank');
    
  }

  return (
    <div
      className={classnames("student-canvas-preview-root", {
        isView: mode === PreviewMode.VIEW && !isDetail,
        isDetail: isDetail,
      })}
    >
      <div className="preview-header">
        <div className="logo-left-sec">
          <span
            className="btn-back"
            onClick={() => onGoBack && onGoBack()}
            hidden={!isDetail}
          >
            <img alt="" src={IconBack} />
            Back
          </span>
          <span className="logo-sec">
            {pageData.logo && pageData.logo.image ? (
              <img alt="" src={pageData.logo.image} />
            ) : (
              <span className="default-avatar">
                {(pageData.name && pageData.name[0]) || ""}
              </span>
            )}
          </span>
        </div>

        <div className="space" />
        <div className="guide-sec">
          <span
            className="guide-text"
            onClick={() => elementIntoView("about-me", 100)}
          >
            ABOUT ME
          </span>
          <span
            className="guide-text"
            onClick={() => elementIntoView("awards", 100)}
            hidden={
              _.isEmpty(pageData.awardList) && mode !== PreviewMode.CREATE
            }
          >
            CELEBRATIONS, CERTIFICATIONS & AWARDS
          </span>
          <span
            className="guide-text"
            onClick={() => elementIntoView("extra-info", 100)}
            hidden={
              _.isEmpty(pageData.preferredBrands) &&
              _.isEmpty(pageData.specialities) &&
              _.isEmpty(pageData.softwares) &&
              _.isEmpty(pageData.employment) &&
              _.isEmpty(pageData.payment.name) &&
              mode === PreviewMode.VIEW
            }
          >
            ADDITIONAL INFO
          </span>
          <span
            className="guide-text"
            onClick={() => elementIntoView("social-media", 100)}
            hidden={
              _.isEmpty(
                _.filter(pageData.socialMediaList, (item) => {
                  return item.value;
                })
              ) && PreviewMode.VIEW === mode
            }
          >
            SOCIAL MEDIA
          </span>
          {/* <Button className="btn-primary-light contact-btn">
            <img alt="" src={IconEmail} />
            CONTACT ME
          </Button> */}
        </div>
        <img
          alt=""
          src={showMenu ? IconClose : IconMenu}
          className="icon-menu"
          onClick={() => {
            setShowMenu(!showMenu);
          }}
        />
      </div>
      <div className="dropdown-menu" hidden={!showMenu}>
        <span
          className="menu-label"
          onClick={() => {
            elementIntoView("about-me", 100);
            setShowMenu(false);
          }}
        >
          ABOUT ME
        </span>
        <span
          className="menu-label"
          onClick={() => {
            elementIntoView("awards", 100);
            setShowMenu(false);
          }}
          hidden={_.isEmpty(pageData.awardList) && mode !== PreviewMode.CREATE}
        >
          CELEBRATIONS, CERTIFICATIONS & AWARDS
        </span>
        <span
          className="menu-label"
          onClick={() => {
            elementIntoView("extra-info", 100);
            setShowMenu(false);
          }}
          hidden={
            _.isEmpty(pageData.preferredBrands) &&
            _.isEmpty(pageData.specialities) &&
            _.isEmpty(pageData.softwares) &&
            _.isEmpty(pageData.employment) &&
            _.isEmpty(pageData.payment.name) &&
            mode === PreviewMode.VIEW
          }
        >
          ADDITIONAL INFO
        </span>
        <span
          className="menu-label"
          onClick={() => {
            elementIntoView("social-media", 100);
            setShowMenu(false);
          }}
          hidden={
            _.isEmpty(
              _.filter(pageData.socialMediaList, (item) => {
                return item.value;
              })
            ) && PreviewMode.VIEW === mode
          }
        >
          Social Media
        </span>
        {/* <Button className="btn-primary-light contact-btn">
          <img alt="" src={IconEmail} />
          CONTACT ME
        </Button> */}
        <div className="divider-menu" />
        <div className="made-with">
          Made with
          <span>
            <Link to={router_homepage_index}>CANVAS ME</Link>
          </span>
        </div>
      </div>
      { pageData.bookingUrl && 
        <Button 
          id="stickyButton"
          className={`btn-primary cm-book-button ${isBtnSticky ? 'cm-sticky-book-btn' : 'cm-not-sticky-book-btn'}`}
          onClick={() => bookBtnClicked() }
          style={bookButtonStyle}
          >
            Book a Service
        </Button> }
      <div className="title-sec" style={pageData.bookingUrl ? {} : {marginTop: '58px'}} id="title">
        <h1 className="student-name">
          {pageData.name || (mode === PreviewMode.VIEW ? "" : "Student Name")}
        </h1>
        {pageData.pronouns && (
          <span className="tag">
            {pageData.pronouns}
          </span>
        )}
        <span className="tag">
          {type === CanvasType.STUDENT ? "Student" : "Professional"}
        </span>
        {pageData.opportunityStatus && pageData.showOpportunityStatus && (
          <span className="tag">Open to Opportunity</span>
        )}
      </div>

      <div className="subtitle">
        {getSubtitle()}
        {/* {(pageData.location && pageData.location.location) || "Location"} */}
      </div>
      <div className="msg-sec">
        <Button
          className="msg-btn btn-primary-light"
          onClick={() => mode === PreviewMode.VIEW && onMessage && onMessage()}
        >
          <img alt="" src={IconMessage} />
          Message me
        </Button>
        
        <Button
          className="msg-btn btn-secondary"
          onClick={() => addOrRemoveBookmark()}
        >
          <img alt="" src={IconBookmark} />
          {isBookmark ? "Remove Bookmark" : "Bookmark"}
        </Button>

        <Button
          className="msg-btn btn-secondary"
          onClick={() => {
            if (mode !== PreviewMode.VIEW) {
              return;
            }
            const apiHost = process.env.REACT_APP_BASE_URL;
            window.open(
              `${
                apiHost?.endsWith("/") ? apiHost : apiHost + "/"
              }canvas/canvas-pdf/${pageData.id}`
            );
          }}
        >
          <img alt="" src={IconBookmark} />
          Save as PDF
        </Button>
      </div>
      <div
        className="featured-img-sec"
        hidden={
          (!pageData.featuredPhoto || !pageData.featuredPhoto.image) &&
          mode === PreviewMode.VIEW
        }
      >
        {pageData.featuredPhoto && pageData.featuredPhoto.image ? (
          <img
            alt=""
            className="featured-img"
            src={getOptimizedPath(pageData.featuredPhoto.image, 1920)}
            style={{
              objectFit: pageData.featuredPhoto.keepSize ? "contain" : "cover",
              ...(pageData.featuredPhoto.reposition? {
                objectPosition: `${pageData.featuredPhoto.reposition.left} ${pageData.featuredPhoto.reposition.top}`,
                objectFit: 'none'
                // ,width: `${pageData.featuredPhoto.reposition.width}`,height: `${pageData.featuredPhoto.reposition.height}`
              } : {})
            }}
          />
        ) : (
          <div className="featured-empty">
            <img alt="" src={IconFeatureEmpty} />
            Featured Image
          </div>
        )}
      </div>
      <div
        className="media-sec"
        id="about-me"
        hidden={
          (!pageData.portraitPhoto || !pageData.portraitPhoto.image) &&
          _.isEmpty(pageData.relocateList) &&
          !pageData.aboutMe &&
          mode === PreviewMode.VIEW
        }
      >
        <div
          className={`portrait-photo-wrap ${
            !pageData.portraitPhoto?.image && "hidden"
          }`}
        >
          {pageData.portraitPhoto && pageData.portraitPhoto.image ? (
            <LazyLoadImage
              alt=""
              className="portrait-photo"
              src={getOptimizedPath(pageData.portraitPhoto.image, 640)}
              scrollPosition={scrollPosition}
              style={{
                // objectFit: pageData.portraitPhoto.keepSize ? 'contain' : 'cover',
                objectFit: 'contain',
                ...(pageData.portraitPhoto.reposition? {
                  objectPosition: `${pageData.portraitPhoto.reposition.left} ${pageData.portraitPhoto.reposition.top}`,
                  objectFit: 'none'
                  // ,width: `${pageData.portraitPhoto.reposition.width}`,height: `${pageData.portraitPhoto.reposition.height}`
                } : {})
              }}
            />
          ) : (
            <div className="empty-image">
              <img alt="" src={IconImageEmpty} />
              Bio Photo
            </div>
          )}
        </div>
        <div className="text-sec-pro">
          <h1 className="about-me">About me</h1>
          
          <div className="desc-sec">
          <div className="desc-text"
              hidden={mode === PreviewMode.VIEW && !pageData.aboutMe}
              dangerouslySetInnerHTML={{ __html: pageData.aboutMe }} />
            {/* <pre
              className="desc-text"
              hidden={PreviewMode.VIEW === mode && !pageData.aboutMe}
            >
              {pageData.aboutMe?.length > 300 && !aTxtMore
                ? pageData.aboutMe.substr(0, 300) + "..."
                : pageData.aboutMe || "Please input description here"}
              {pageData.aboutMe?.length > 300 &&
                (!aTxtMore ? (
                  <span className="text-more" onClick={() => setATxtMore(true)}>
                    {" "}
                    See More
                  </span>
                ) : (
                  <span
                    className="text-more"
                    onClick={() => setATxtMore(false)}
                  >
                    {" "}
                    See Less
                  </span>
                ))}
            </pre> */}
            <Button
              className="btn-primary-light btn-watch"
              onClick={() => {
                const videoMedia = _.find(mediaList, (item) => {
                  return !_.isEmpty(item.video) || !_.isEmpty(item.url);
                });
                if (videoMedia) {
                  setCurPlayUrl(videoMedia.video || videoMedia.url);
                }
              }}
              hidden={showProVideoBtn()}
            >
              <img alt="" src={IconPlay} />
              Watch my video
            </Button>
          </div>
          <RelocateItem
            relocateList={pageData.relocateList}
            hidden={
              _.isEmpty(pageData.relocateList) && mode === PreviewMode.VIEW
            }
          />
        </div>
      </div>
      <div
        className="education-sec"
        hidden={
          mode === PreviewMode.VIEW &&
          _.isEmpty(
            _.filter(pageData.educations, (item) => {
              return item.schoolName;
            })
          ) &&
          pageData.experience === -1 &&
          _.isEmpty(pageData.categories)
        }
        id="education"
      >
        <h1 className="title">Education</h1>
        <div className="edu-info">
          <EducationBackgroundItem
            educations={pageData.educations}
            hidden={
              _.isEmpty(
                _.filter(pageData.educations, (item) => {
                  return item.schoolName;
                })
              ) && mode === PreviewMode.VIEW
            }
          />
          <SimpleKVItem
            label={"Years of Experience"}
            value={
              pageData.experience === -1 ? "None" : pageData.experience + ""
            }
            hidden={pageData.experience === -1 && mode === PreviewMode.VIEW}
          />
          {type === CanvasType.PROFESSIONAL && (
            <LicenseItem
              categories={pageData.categories}
              hidden={
                _.isEmpty(pageData.categories) && mode === PreviewMode.VIEW
              }
            />
          )}
        </div>
      </div>
      <div
        className="work-sec"
        hidden={mode === PreviewMode.VIEW && _.isEmpty(mediaList)}
        id="my-work"
      >
        <h1 className="canvas-title">My work</h1>
        <div className="img-sec">
          {_.isEmpty(mediaList) ? (
            <div className="img-empty-list">
              {_.map([0, 1, 2], (item, idx) => {
                return (
                  <div className="img-empty-item-wrap">
                    <div className="empty-image" key={idx}>
                      <img alt="" src={IconImageEmpty} />
                      Bio Photo
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="img-list">
              <Slider {...settings} ref={mediaSlider} lazyLoad="ondemand">
                {_.map(mediaList, (item, idx) => {
                  const keepSize = item.image ? item.image.keepSize : pageData.featuredPhoto.keepSize;
                  let position = {};
                  if (item.image) {
                    position = item.image.reposition ? {
                      objectPosition: `${item.image.reposition.left} ${pageData.featuredPhoto.reposition.top}`,
                      objectFit: 'none'
                    } : {};
                  } else {
                    position = pageData.featuredPhoto.reposition ? {
                      objectPosition: `${pageData.featuredPhoto.reposition.left} ${pageData.featuredPhoto.reposition.top}`,
                      objectFit: 'none'                      
                    } : {};
                  }
                  return (
                    <div className="img-item-wrap" key={idx}>
                      {item.image ||
                      (pageData.featuredPhoto &&
                        pageData.featuredPhoto.image) ? (
                        <img
                          alt=""
                          src={item.image ? getOptimizedPath(item.image, 640) : getOptimizedPath(pageData.featuredPhoto.image, 640)}                          
                          style={{
                            objectFit: keepSize ? 'contain' : 'cover',
                            ...(position)
                          }}
                        />
                      ) : (
                        <span className="video-empty-image">
                          {!_.isEmpty(pageData.name) && pageData.name[0]}
                        </span>
                      )}
                      {(item.video || item.url) && (
                        <img
                          alt=""
                          src={IconPlayWhite}
                          className="play-icon"
                          onClick={() => {
                            setCurPlayUrl(item.url || item.video || "");
                          }}
                        />
                      )}
                    </div>
                  );
                })}
              </Slider>
            </div>
          )}
        </div>
        <span className="img-control" hidden={mediaList.length < 4}>
          <img
            src={IconScrollLeft}
            alt=""
            onClick={() => {
              if (mediaSlider && mediaSlider.current) {
                (mediaSlider.current as any)!.slickPrev();
              }
            }}
          />
          <img
            src={IconScrollRight}
            alt=""
            onClick={() => {
              if (mediaSlider && mediaSlider.current) {
                (mediaSlider.current as any)!.slickNext();
              }
            }}
          />
        </span>
      </div>
      <div
        className="why-sec"
        id="our-team"
        hidden={(!pageData.why || !pageData.why.trim()) && mode === PreviewMode.VIEW}
      >
        <span
          className="canvas-title"
          hidden={!pageData.why && mode === PreviewMode.VIEW}
        >
          Why I'm here
        </span>
        <div className="why-desc"
              hidden={mode === PreviewMode.VIEW && !pageData.why}
              dangerouslySetInnerHTML={{ __html: pageData.why }} />
        {/* <pre
          className="why-desc"
          hidden={!pageData.why && mode === PreviewMode.VIEW}
        >
          {pageData.why || "Please input description here (250 letters)."}
        </pre> */}
        {/* <Button
          className="btn-primary-light btn-drop"
          onClick={() => {
            sendMessage();
          }}
        >
          <img alt="" src={IconEmail} />
          Drop me a letter
        </Button> */}
      </div>
      <div
        className="kpi-sec"
        hidden={
          !pageData.kpi.combinedAverageTicket &&
          !pageData.kpi.annualFrequencyOfVisit &&
          !pageData.kpi.repeatClientRetention &&
          !pageData.kpi.serviceToRetailSales &&
          mode === PreviewMode.VIEW
        }
        id="kpi"
      >
        <span className="canvas-title">Key Performance Indicators</span>
        <div className="kpi-list">
          <div
            className="kpi-item"
            hidden={
              !pageData.kpi.combinedAverageTicket && mode === PreviewMode.VIEW
            }
          >
            <span className="value">{`$${
              (pageData.kpi && pageData.kpi.combinedAverageTicket) || 0
            }`}</span>
            <span className="key">Combined Average Ticket</span>
          </div>
          <div
            className="kpi-item"
            hidden={
              !pageData.kpi.annualFrequencyOfVisit && mode === PreviewMode.VIEW
            }
          >
            <span className="value">
              {(pageData.kpi && pageData.kpi.annualFrequencyOfVisit) || 0}
            </span>
            <span className="key">Annual Frequency of Visit</span>
          </div>
          <div
            className="kpi-item"
            hidden={
              !pageData.kpi.repeatClientRetention && mode === PreviewMode.VIEW
            }
          >
            <span className="value">{`${
              (pageData.kpi && pageData.kpi.repeatClientRetention) || 0
            }%`}</span>
            <span className="key">Repeat Client Retention</span>
          </div>
          <div
            className="kpi-item"
            hidden={
              !pageData.kpi.serviceToRetailSales && mode === PreviewMode.VIEW
            }
          >
            <span className="value">{`${
              (pageData.kpi && pageData.kpi.serviceToRetailSales) || 0
            }%`}</span>
            <span className="key">Service to Retail Sales</span>
          </div>
        </div>
      </div>
      <div
        className="additional-sec"
        id="extra-info"
        hidden={
          _.isEmpty(pageData.preferredBrands) &&
          _.isEmpty(pageData.specialities) &&
          _.isEmpty(pageData.softwares) &&
          _.isEmpty(pageData.employment) &&
          _.isEmpty(pageData.payment.name) &&
          mode === PreviewMode.VIEW
        }
      >
        <span className="canvas-title">Additional Information</span>
        <div className="additional-info-list">
          <div
            className={classnames("additional-info-item")}
            hidden={
              _.isEmpty(pageData.preferredBrands) && mode === PreviewMode.VIEW
            }
          >
            <span className="info-title">Preferred Brands</span>
            <div className="info-item-list">
              {_.map(
                _.isEmpty(pageData.preferredBrands)
                  ? retailPH
                  : (pageData.preferredBrands as any[]),
                (item, idx) => {
                  return (
                    <div
                      className="info-item-list-item"
                      key={`list-item${idx}`}
                    >
                      <span className="value">{item.name}</span>
                      <span
                        className="point"
                        hidden={
                          idx ===
                          (_.isEmpty(pageData.preferredBrands)
                            ? retailPH.length - 1
                            : pageData.preferredBrands.length - 1)
                        }
                      />
                    </div>
                  );
                }
              )}
            </div>
          </div>
          <div
            className={classnames("additional-info-item")}
            hidden={
              _.isEmpty(pageData.specialities) && mode === PreviewMode.VIEW
            }
          >
            <span className="info-title">Specialties</span>
            <div className="info-item-list">
              {_.map(
                _.isEmpty(pageData.specialities)
                  ? specialtiesPH
                  : (pageData.specialities as any[]),
                (item, idx) => {
                  return (
                    <div
                      className="info-item-list-item"
                      key={`list-item${idx}`}
                    >
                      <span className="value">{item.name}</span>
                      <span
                        className="point"
                        hidden={
                          idx ===
                          (_.isEmpty(pageData.specialities)
                            ? specialtiesPH.length - 1
                            : pageData.specialities.length - 1)
                        }
                      />
                    </div>
                  );
                }
              )}
            </div>
          </div>
          <div
            className={classnames("additional-info-item")}
            hidden={_.isEmpty(pageData.softwares) && mode === PreviewMode.VIEW}
          >
            <span className="info-title">Software</span>
            <div className="info-item-list">
              {_.map(
                _.isEmpty(pageData.softwares)
                  ? softwarePH
                  : (pageData.softwares as any[]),
                (item, idx) => {
                  return (
                    <div
                      className="info-item-list-item"
                      key={`list-item${idx}`}
                    >
                      <span className="value">{item.name}</span>
                      <span
                        className="point"
                        hidden={
                          idx ===
                          (_.isEmpty(pageData.softwares)
                            ? softwarePH.length - 1
                            : pageData.softwares.length - 1)
                        }
                      />
                    </div>
                  );
                }
              )}
            </div>
          </div>
          <div
            className={classnames("additional-info-item")}
            hidden={
              (_.isEmpty(pageData.payment.name) && mode === PreviewMode.VIEW) ||
              pageData.payment.name === "Don't show" ||
              173 === pageData.payment.id
            }
          >
            <span className="info-title">Payment Preference</span>
            <div className="info-item-list">
              <div className="info-item-list-item">
                <span
                  className={classnames("value", {
                    isLong: true,
                  })}
                >
                  {(pageData.payment && pageData.payment.name) ||
                    "Payment Preference"}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          className={classnames("additional-info-item-employ")}
          hidden={_.isEmpty(pageData.employment) && mode === PreviewMode.VIEW}
          id="employ-info"
        >
          <span className="canvas-title info-title">
            Employment Information
          </span>
          <div className="info-item-list-employ">
            {_.map(
              _.isEmpty(pageData.employment)
                ? employmentPH
                : (_.take(pageData.employment, showMoreEmp ? 99 : 3) as any[]),
              (item, idx) => {
                return (
                  <div
                    className="info-item-list-item-employ"
                    key={`list-item${idx}`}
                  >
                    <span
                      className={classnames("number", {
                        small: idx > 6,
                      })}
                    >
                      {idx + 1}
                    </span>
                    <div className="employment-right-sec">
                      <span className="value">{item.title || ""}</span>
                      <span className="issue">
                        {item.company
                          ? getIssueText(item)
                          : mode === PreviewMode.VIEW
                          ? ""
                          : "Employment Date"}
                      </span>
                      <span className="description">
                        {item.description?.length > 200 && !txtMore[idx]
                          ? item.description.substr(0, 200) + "..."
                          : item.description ||
                            `${item.isPlaceHolder ? "Description" : ""}`}
                        {item.description?.length > 200 &&
                          (!txtMore[idx] ? (
                            <span
                              className="text-more"
                              onClick={() => {
                                txtMore[idx] = true;
                                setTxtMore([...txtMore]);
                              }}
                            >
                              {" "}
                              See More
                            </span>
                          ) : (
                            <span
                              className="text-more"
                              onClick={() => {
                                txtMore[idx] = false;
                                setTxtMore([...txtMore]);
                              }}
                            >
                              {" "}
                              See less
                            </span>
                          ))}
                      </span>
                    </div>
                  </div>
                );
              }
            )}
            {pageData.employment && pageData.employment.length > 3 && (
              <div>
                <Button
                  className="btn-secondary see-more"
                  onClick={() => {
                    setShowMoreEmp(!showMoreEmp);
                  }}
                >
                  See {showMoreEmp ? "Less" : "More"}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className="awards-sec"
        hidden={_.isEmpty(pageData.awardList) && mode !== PreviewMode.CREATE}
        id="awards"
      >
        <span className="canvas-title">Celebrations, Certifications & Awards</span>
        <div className="awards-list">
          {_.map(
            !_.isEmpty(pageData.awardList) ? pageData.awardList : [0, 1, 2, 3],
            (item, idx) => {
              return (
                <div className="awards-item-empty" key={idx}>
                  {item?.title?.includes('Redken Safe Space Educated') ? (
                    <a href="https://u.canvasme.com/course/safe-space/" target="blank">
                      <img alt="Redken Award" style={{ width: '100px' }} src={getStaticAssetPath('redken.png')} />
                      <Tooltip title={item.title || "Title"}>
                        <Paragraph
                          className="title"
                        >
                          {item.title || "Title"}
                        </Paragraph>
                      </Tooltip>
                      <Tooltip title={getAwardText(item)}>
                        <Paragraph
                          ellipsis={{ rows: 1, expandable: true }}
                          className="issue"
                        >
                          {getAwardText(item)}
                        </Paragraph>
                      </Tooltip>
                    </a>
                  ):(
                    <>
                      {
                        item?.title?.includes('NAMI Head Up Completion') && (
                          <img
                            alt="Head Up Completion"
                            style={{ width: '200px' }}
                            src={getStaticAssetPath('heads-up-badge.png')}
                          />
                        )
                      }

                      {
                        item?.title?.includes('Purpose Discovery') && (
                          <img
                            alt="Purpose Discovery/EQ 101"
                            style={{ width: '100px' }}
                            src={getStaticAssetPath('defining-your-purpose-badge.svg')}
                          />
                        )
                      }

                      {
                        item?.title === 'Beacon' && item?.issueYear === '2024' &&  (
                          <>
                            <img
                              alt="Beacon Badge"
                              style={{ width: '100px' }}
                              src={getStaticAssetPath('pba-beacon-badge.png')}
                            />
                          </>
                        )
                      }

                      {
                        item?.title?.includes('Biolage') && (
                          <>
                            <img
                              alt="Biolage Badge"
                              style={{ width: '100px' }}
                              src={getStaticAssetPath('biolage.svg')}
                            />
                          </>
                        )
                      }

                      {/* If the title does not match any of the above, display the default IconAward */}
                      {
                        (!item?.title?.includes('NAMI Head Up Completion') &&
                        !item?.title?.includes('Purpose Discovery Completion') &&
                        !item?.title?.includes('Purpose Discovery/EQ 101') && 
                        !item?.title?.includes('Biolage') &&
                        !(item?.title === 'Beacon' && item?.issueYear === '2024')) &&
                        <img alt="" src={IconAward} />
                      }
                      <Tooltip title={item.title || "Title"}>
                        <Paragraph
                          className="title"
                        >
                          {item.title || "Title"}
                        </Paragraph>
                      </Tooltip>
                      <Tooltip title={getAwardText(item)}>
                        <Paragraph
                          ellipsis={{ rows: 1, expandable: true }}
                          className="issue"
                        >
                          {getAwardText(item)}
                        </Paragraph>
                      </Tooltip>
                    </>
                  )}
                </div>
              );
            }
          )}
        </div>
      </div>
      <div
        className="ins-sec"
        hidden={!insName && PreviewMode.VIEW === mode}
        id="ins"
      >
        <div className="ins-top-sec">
          <div className="follow-wrap">
            <span className="follow">Follow me ON Instagram</span>
          </div>

          <div className="ins-name-sec">
            <span className="ins-name">{getInsDisplayName()}</span>
            <Button
              className="follow-btn btn-primary-light"
              onClick={() =>
                insName &&
                window.open(
                  `https://www.instagram.com/${insName.replace(/^@/gi, "")}`
                )
              }
            >
              FOLLOW
            </Button>
          </div>
        </div>
        {!_.isEmpty(insPhotos) ? (
          <>
            {isMobile ? (
              <div className="carousel-wrap">
                <Carousel dots={false} centerMode={true} centerPadding="40px">
                  {RenderInsList(insPhotos, true)}
                </Carousel>
              </div>
            ) : (
              <div className="ins-list">{RenderInsList(insPhotos)}</div>
            )}
          </>
        ) : (
          <>
            {mode !== PreviewMode.VIEW && 
              <>
                {isMobile ? (
                  <div className="carousel-wrap">
                    <Carousel dots={false} centerMode={true} centerPadding="40px">
                      {_.map([0, 1, 2, 3], (num) => {
                        return (
                          <div className="ins-item-empty" key={num}>
                            <div className="center-wrap">
                              <img alt="" src={IconImageEmpty} />
                              <span>Instagram post</span>
                            </div>
                          </div>
                        );
                      })}
                    </Carousel>
                  </div>
                ) : (
                  <div className="ins-list">
                    {_.map([0, 1, 2, 3], (num) => {
                      return (
                        <div className="ins-item-empty" key={num}>
                          <div className="center-wrap">
                            <img alt="" src={IconImageEmpty} />
                            <span>Instagram post</span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </>
            }
          </>
        )}
      </div>
      <div className="address-sec" hidden={!pageData.location.location}>
        <div className="address-list">
          <span className="canvas-title location-title">Location</span>
          <div
            className={classnames("address-item", {
              active: true,
            })}
          >
            <span className="value">
              {(pageData.location && pageData.location.location) ||
                "757 S High St, Columbus, OH 43206, United States"}
            </span>
          </div>
        </div>
        <div className="map-sec" id="map">
          <img alt="" src={PlaceHolderMap} hidden={!showMapPH} />
        </div>
      </div>
      <div className="footer-media-sec" id="social-media">
        {(!_.isEmpty(
          _.filter(pageData.socialMediaList, (item) => {
            return item.value;
          })
        ) ||
          mode === PreviewMode.CREATE) && (
          <>
            <div className="media-content">
              <span className="canvas-title">Follow me on social media</span>
              <div className="icon-list">
                {_.map(
                  PreviewMode.VIEW === mode
                    ? _.filter(pageData.socialMediaList, (item) => {
                        return item.value;
                      })
                    : pageData.socialMediaList,
                  (social, index) => {
                    return (
                      <img
                        alt=""
                        src={getSocialIcon(social.label)}
                        key={index}
                        onClick={() => redirectToSocial(social)}
                      />
                    );
                  }
                )}
              </div>
            </div>
            <div className="media-divider" />
          </>
        )}
        <div className="made-with">
          Made with
          <span>
            <Link to={router_homepage_index}>CANVAS ME</Link>
          </span>
        </div>
      </div>
      {!_.isEmpty(curPlayUrl) && (
        <VideoModal
          visible
          onClose={() => setCurPlayUrl("")}
          url={curPlayUrl}
        />
      )}

      <Modal
        footer={null}
        closable={false}
        onCancel={onMsgClose}
        visible={visibleMsgModal}
        centered={true}
        width="80%"
      >
        <div className="send-msg-modal">
          <div className="common-title">Send Message</div>
          <div className="modal-text">You are sending a message via email.</div>
          <TextField
            label="Message goes here"
            placeholder="Message goes here"
            multiline
            rows={4}
            variant="filled"
            className="material-input"
            value={msgContent}
            onChange={(event) => setMsgContent(event.target.value)}
          />
          <div className="modal-text">
            Clicking on <strong>Send My Message</strong> will initiate a email
            that goes out to this user.
          </div>
          <div className="btn-wrap">
            <Button type="primary" onClick={sendMsg}>
              Send Message
            </Button>
            <Button onClick={onMsgClose}>Cancel</Button>
          </div>
        </div>
      </Modal>
      <Modal
        footer={null}
        onCancel={() => setVisibleSuccessModal(false)}
        visible={visibleSuccessModal}
        centered={true}
        width={400}
      >
        <div className="comfirm-modal">
          <div className="common-title">Message sent!</div>
          <div className="modal-text">
            Woohoo! Your message was sent successfully.
          </div>
          <img src={IconSuccess} alt="success" />
          <div className="btn-wrap">
            <Button
              type="primary"
              onClick={() => setVisibleSuccessModal(false)}
            >
              Close
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default trackWindowScroll(CanvasPreview);
